<template>
  <div class="onboard-terms onboard-terms-page">
    <div class="bread-marz">
    <router-link
      :to="{
        name: 'reviewApplication',
        params: {
          introducerRequestedId: this.$route.params.introducerRequestedId,
        },
      }"
    >
      <feather-icon
        icon="ChevronLeftIcon"
        class="cursor-pointer w-5 h-5 mx-2"
        style="vertical-align: middle; color:#757575"
      />Back to review
    </router-link>
    </div>
    <h2
      class="font-medium text-xl md:text-2xl lg:text-3xl mt-6 md:mt-0 mb-6 md:mb-8 lg:mb-10"
    >
      Terms & Conditions
    </h2>



    <vx-card class="accordin-card mb-5 terms-card">
      <div class="vx-card__body bsv p-0">
        <vs-collapse accordion>
          <vs-collapse-item open>
            <div slot="header">
              <div>
                <h3 class="text-left card-title font-medium">
                  Authorisation
                  <span class="mid-blue align-top">*</span>
                </h3>
              </div>
            </div>
            <vs-row>
              <div>
                 <p class="font-normal">
                   Please confirm you are an authorised signatory who’s been given the right to sign documents on behalf of the following entity/ies:
                </p>
                <ul v-if="onboardDetail && onboardDetail.businessOwnership" class="text-base font-normal list-disc">
                  <li v-for="(owner,entindex) in onboardDetail.businessOwnership" :key="owner._id" class="ml-6">
                    Entity {{entindex+1}}: {{owner.fullName || owner.companyName}}
                  </li>
                </ul>
              </div>
                <div class="flex w-full items-start mt-6">
                  <div class="flex items-center justify-center">
                    <div class="input-grp">
                      <label class="text-base p-0 w-full text-dark-blue">Full Name</label>
                      <vs-input
                        v-model="terms.autorizedPerson.name"
                        :name="`authTermsFullName`"
                        data-vv-as="full name"
                        class="w-full surcharge-top"

                      />
                      <span
                        v-show="errors.has(`authTermsFullName`)"
                        class="text-danger text-sm"
                        >{{ errors.first(`authTermsFullName`) }}</span
                      >
                    </div>
                     <div class="input-grp">
                      <label class="text-base p-0 w-full text-dark-blue">Position</label>
                      <vs-input
                        v-model="terms.autorizedPerson.position"
                        :name="`authTermsPosition`"
                        data-vv-as="full name"
                        class="w-full surcharge-top"

                      />
                      <span
                        v-show="errors.has(`authTermsPosition`)"
                        class="text-danger text-sm"
                        >{{ errors.first(`authTermsPosition`) }}</span
                      >
                    </div>
                  </div>
                </div>

              <div class="flex w-full items-start mt-6 md:mt-8">

                <div class="flex items-center justify-center">
                  <vs-checkbox  :disabled="isAuthorizedPersonComplete"  v-model="terms.autorizedTerms"></vs-checkbox>
                </div>

                <div class="ml-2">

                  <div>
                     I am an authorised signatory
                  </div>
                </div>
              </div>
            </vs-row>
          </vs-collapse-item>
        </vs-collapse>
      </div>
    </vx-card>


    <vx-card v-if="isAuthorizedTermsComplete" class="accordin-card mb-5 terms-card">
      <div class="vx-card__body bsv p-0">
        <vs-collapse accordion>
          <vs-collapse-item open>
            <div slot="header">
              <div>
                <h3 class="text-left card-title font-medium">
                  {{ (payNowTermsDetail.merchantOnbaordingTerms)?payNowTermsDetail.merchantOnbaordingTerms.documentName:"" }}
                  <span class="mid-blue align-top">*</span>
                </h3>
              </div>
            </div>
            <vs-row>
              <div class="flex w-full items-start">
                <div class="flex items-center justify-center">
                  <vs-checkbox v-model="terms.generalTerms"></vs-checkbox>
                </div>
                <div class="ml-2">
                  <div
                    v-html="
                      payNowTermsDetail.merchantOnbaordingTerms.explicitTerms
                    "
                  ></div>
                </div>
              </div>
            </vs-row>
          </vs-collapse-item>
        </vs-collapse>
      </div>
    </vx-card>

    <vs-row  v-if="isAuthorizedTermsComplete" id="profile-page" class="mt-5">
      <form autocomplete="off" class="w-full" @submit.prevent="saveAsDraft">
        <vx-card
          v-for="(product, key) in productSelection"
          :key="key"
          class="accordin-card mb-5"
        >
          <!-- {{product.legals}} -->
          <div
            v-if="
              ((productOptions.includes('pay-later') &&
                product.isChecked == 1) ||
                product.isChecked) &&
              product.isMerchantLegalActive
            "
            class="vx-card__body bsv p-0"
          >
            <vs-collapse accordion>
              <vs-collapse-item open>
                <div slot="header">
                  <div>
                    <h3 class="font-medium">
                      {{ product.productName }} <span class="mid-blue align-top">*</span>
                    </h3>
                  </div>
                </div>
                <vs-row>
                  <div class="flex w-full">
                    <div>
                      <vs-checkbox
                        v-model="terms.productTerms"
                        :vs-value="product._id"
                      ></vs-checkbox>
                    </div>

                    <div class="ml-2">
                        <p
                          v-if="product.legals && product.legals.length > 0"
                          v-html="product.legals[0].explicitTerms"
                        ></p>
                    </div>
                  </div>
                </vs-row>
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </vx-card>

        <vx-card
          v-if="productOptions.includes('pay-now')"
          class="accordin-card mb-5"
        >
          <div class="vx-card__body bsv p-0">
            <vs-collapse accordion>
              <vs-collapse-item open ref="payNowcard">
                <div slot="header">
                  <div>

                    <h3 class="font-medium">
                      {{ payNowTermsDetail.payNow.documentName }}
                      <span class="mid-blue align-top">*</span>
                    </h3>
                  </div>
                </div>

                <vs-row>
                  <div class="flex w-full items-start mb-8">
                    <div class="flex items-center justify-center">
                      <vs-checkbox v-model="terms.payNowTerms"></vs-checkbox>
                    </div>
                    <div class="ml-2">
                        <ul
                          class="list-none"
                          v-if="payNowTermsDetail && payNowTermsDetail.payNow"
                        >
                          <li
                            class="text-base list-none"
                            v-html="payNowTermsDetail.payNow.explicitTerms"
                          ></li>
                        </ul>
                      </div>
                  </div>

                  <hr class="line-hr w-full" />

                  <div class="mt-8">
                    <div class="flex w-full">
                      <div>
                        <h4 class="lg:text-xl md:text-lg text-sm font-normal">
                          Digital signature required <span class="mid-blue align-top">*</span>
                        </h4>
                        <p class="text-base">
                         This document requires a signature from an authorised signatory. Scroll to the bottom to find the digital signature block.
                        </p>
                      </div>
                    </div>
                  </div>




                  <vs-col
                    vs-w="12"
                    v-for="(sign, key) in singersDetail"
                    :key="key"
                    class="px-4 py-6"
                  >

                    <iframe
                      class="mb-5"
                      style="border: 0"
                      width="100%"
                      height="500"
                      :src="sign.sign_page_url"
                    >
                    </iframe>
                  </vs-col>
                </vs-row>
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </vx-card>

        <!--   Buttons   -->
        <div class="mt-10 flex w-full flex-wrap justify-end">
          <vs-button
            flat
            size="large"
            v-round
            class="only-border-btn lg:mt-0 md:mb-0 mb-4"
            @click="updateTerms()"
          >
            Save
          </vs-button>
          <vs-button
            flat
            size="large"
            v-round
            class="ml-8"
            :disabled="isValidTermsPage"
            @click="submitCompleteApplication()"
          >
            Submit
          </vs-button>
        </div>
      </form>
    </vs-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import _ from "lodash";
export default {
  name: "Terms",
  data() {
    return {
      esignetureErrorMessage: "",
      appUrl: process.env.VUE_APP_BASE_URL,
      isSignersSigned: false,
      contractDetail: {},
      singersDetail: {},
      productSelection: [],
      onboardDetail: {},
      productOptions: [],
      payNowTermsDetail: {},
      terms: {
        autorizedTerms:false,
        autorizedPerson:{
          name:"",
          position:""
        },
        generalTerms: false,
        generalTermsId: "",
        payNowTerms: false,
        productTerms: [],
      },
      id: "",
      interval: null,
    };
  },
  methods: {
    ...mapActions("onboardMerchant", [
      "saveWebForm",
      "fetchOnboardDetailWithId",
      "updateTermsAndConditions",
      "getPayNowTerm",
      "submitApplication",
      "sendCodeForSignature",
      "getPayLaterTerm",
    ]),

    getDetails() {
      this.$vs.loading();
      this.fetchOnboardDetailWithId(this.id)
        .then((result) => {
          this.onboardDetail = result.data.data;
          this.productOptions = result.data.data.productSelection.productOption;
          this.productSelection = result.data.data.productSelection.payLaterProducts;
          this.terms = result.data.data.terms;
          if (!result.data.data.terms.hasOwnProperty("generalTermsId")) {
            this.terms.generalTermsId = this.payNowTermsDetail.merchantOnbaordingTerms._id || this.terms.generalTermsId;
          }
          this.getPayLaterTerms(this.productSelection);
          this.hasDataLoaded = true;
          this.$vs.loading.close();
        }).catch((err) => {
          this.$vs.loading.close();
        });
    },
    async updateTerms() {
      this.$vs.loading();
      const data = {};
      data.terms = this.terms;
      data.onboardingRequestId = this.id;
      await this.updateTermsAndConditions(data)
        .then((response) => {
          this.$vs.loading.close();
        }).catch((err) => {
          this.$vs.loading.close();
        });
    },
    async getPayNowTerms() {
      await this.getPayNowTerm()
        .then((response) => {
          this.payNowTermsDetail = response.data.data;
          this.payNowTermsDetail.merchantOnbaordingTerms.explicitTerms = this.replaceLegalContent(
            this.payNowTermsDetail.merchantOnbaordingTerms.explicitTerms,
            {
              fullTerms: `<a href="${this.appUrl}legals/MERCHANT-ONBOARDING-T&C?id=${this.payNowTermsDetail.merchantOnbaordingTerms._id}" target="_blank">${this.payNowTermsDetail.merchantOnbaordingTerms.documentName}</a>`,
              privacy: `<a href="${this.appUrl}legals/PRIVACY" target="_blank">${this.payNowTermsDetail.privacy.documentName}</a>`,
              generalWebsiteTerms:`<a href="${this.appUrl}legals/GENERAL-WEBSITE-TERMS" target="_blank">${this.payNowTermsDetail.generalWebsiteTerms.documentName}</a>`,

            }
          );
          this.payNowTermsDetail.payNow.explicitTerms = this.replaceLegalContent(
            this.payNowTermsDetail.payNow.explicitTerms,
            {
              fullTerms: `<a href="${this.appUrl}legals/PAYNOW_TC?id=${this.payNowTermsDetail.payNow._id}" target="_blank">${this.payNowTermsDetail.payNow.documentName}</a>`,
              privacy: `<a href="${this.appUrl}legals/PRIVACY" target="_blank">${this.payNowTermsDetail.privacy.documentName}</a>`,
              generalWebsiteTerms:`<a href="${this.appUrl}legals/GENERAL-WEBSITE-TERMS" target="_blank">${this.payNowTermsDetail.generalWebsiteTerms.documentName}</a>`,
            }
          );
          this.getDetails();
        });
    },
    async submitCompleteApplication() {
      this.$vs.loading();
      const data = {
        userId: this.id,
      };
      const newProductArray = this.productSelection.map((item) => {
        item.legals = item.legals.map((legalsData) => {
          return {
            _id: legalsData._id,
            version: legalsData.version,
            type: legalsData.type,
            user: legalsData.user
          };
        });
        return item;
      });
      data.productSelection = newProductArray;
      data.applicationTerms = this.terms;
      await this.submitApplication(data)
        .then((result) => {
          this.$vs.loading.close();
          if (result.data.data) {
            this.$router.push({
              name: "SubmissionApplication",
              params: {
                introducerRequestedId: result.data.data.onboardingRequestId,
              },
            });
          }
        }).catch((err) => {
          this.$vs.loading.close();
        });
    },
    async sendCode() {
      const data  = {};
      data.email = "";
      data.firstName = this.terms.autorizedPerson.name;
      data.onboardingRequestId = this.id;
      data.companyName = this.onboardDetail.businessInfo.tradingName;
      await this.sendCodeForSignature(data).then((response) => {
        this.handleCardHeight();
        if (response.data.data.api.status == "error") {
          this.esignetureErrorMessage = "Unable to send contract ";
        } else {
          this.esignetureErrorMessage = "";
        }
        this.singersDetail = response.data.data.api.data.contract.signers;
        this.contractDetail = response.data.data.api.signers;
      });
    },
    handleCardHeight() {
      this.$refs.payNowcard.maxHeight = "none !important";
    },

    checkSignedStatus() {
      if (this.isSignersSigned === false) {
        this.fetchOnboardDetailWithId(this.id).then((result) => {
          const authSigned = result.data.data.terms.autorizedPerson;
          let count = 0;
          if ((authSigned.esignatures && authSigned.esignatures.status === "contract-signed") || authSigned.esignatures.status === "signer-signed") {
            count = count + 1;
          }
          if (count > 0) {
            this.isSignersSigned = true;
          }
        });
      }
    },
    sendRequestInEvery5Sec: function (isSign) {
      if (!isSign) {
        this.interval = setInterval(() => {
          this.checkSignedStatus();
        }, 5000);
      }
    },

    getLatestMerchantLegalForProduct(val) {
      const terms = val.legals.filter((legal) => legal.user === "MERCHANT");
      return _.sortBy(terms, ["createdAt"]).reverse()[0];
    },

    getPayLaterTerms(product) {
      product.forEach(async (item, index) => {
        const legalData = await this.getPayLaterTerm(item._id).then(
          (response) => {
            const dataToBefilter = response.data.data.legals.filter((item) => item.user == "MERCHANT");
            const lastLindex = dataToBefilter.length - 1;
            const legals = dataToBefilter[lastLindex];
            legals.explicitTerms = this.replaceLegalContent(
              legals.explicitTerms,
              {
                fullTerms: `<a href="${this.appUrl}legals/PRODUCT_TC?id=${legals._id}" target="_blank">${legals.documentName}</a>`,
                privacy: `<a href="${this.appUrl}legals/PRIVACY" target="_blank">${this.payNowTermsDetail.privacy.documentName}</a>`,
                generalWebsiteTerms:`<a href="${this.appUrl}legals/GENERAL-WEBSITE-TERMS" target="_blank">${this.payNowTermsDetail.generalWebsiteTerms.documentName}</a>`,
              }
            );
            return legals;
          }
        );
        item.legals = [];
        item.legals.push(legalData);
      });
    },
  },

  created() {
    this.id = this.$route.params.introducerRequestedId;
  },

  mounted() {
    this.getPayNowTerms();
    this.sendRequestInEvery5Sec();
  },
  beforeRouteLeave(to, from , next) {
    clearInterval(this.interval);
    next();
  },
  computed: {
    isValidTermsPage() {
      const data = {...this.terms};
      data.generalTerms = true;
      return !(data.generalTerms && (this.productOptions.includes("pay-now") ? data.payNowTerms && this.isSignersSigned : true));
    },
    countIsCheckedProduct() {
      let count = 0;
      this.productSelection.map((item, index) => {
        if (item.isChecked === "1" || item.isChecked == true) {
          count = count + 1;
        }
      });
      return count;
    },

    isAuthorizedPersonComplete() {
      return (this.terms.autorizedPerson.name && this.terms.autorizedPerson.position) ? false : true;
    },
    isAuthorizedTermsComplete() {
      const status = (this.terms.autorizedPerson.name && this.terms.autorizedPerson.position && this.terms.autorizedTerms);
      if (status) {
        if (!this.isSignersSigned) {
          this.sendCode();
        }
        this.sendRequestInEvery5Sec(this.isSignersSigned);
      }
      return status;
    }
  },
};
</script>